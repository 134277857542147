import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../containers/Layout";
import BrandTrustSection from "../components/VendorEditionPage/BrandTrustSection";
import FeaturesSection from "../components/VendorEditionPage/FeaturesSection";
import Headline from "../components/VendorEditionPage/Headline";
import HeroSection from "../components/VendorEditionPage/HeroSection";
import SolutionItems from "../components/VendorEditionPage/SolutionItems";
import TailoredItemsSection from "../components/VendorEditionPage/TailoredItemsSection";
import AgenciesSection from "../components/VendorEditionPage/AgenciesSection";
import SEO from "../components/Seo";

const VendorEdition = ({ data }) => {
  const { language } = useI18next();
  const {
    seo,
    heroSection,
    firstHeadline,
    solutionItems,
    brandTrustSection,
    secondHeadline,
    featuresSection,
    thirdHeadline,
    tailoredItems,
    agenciesSection
  } = data;
  const { metadataTitle, metadataDescription } = seo.nodes[0].seoSettings;

  return (
    <Layout
      primaryButtonId="nav-getstarted"
      secondaryButtonId="nav-signin"
      locale={language}
    >
      <SEO title={metadataTitle} description={metadataDescription} />
      <HeroSection content={heroSection} />
      <Headline content={firstHeadline} />
      <SolutionItems content={solutionItems} />
      <BrandTrustSection content={brandTrustSection} />
      <Headline content={secondHeadline} />
      <FeaturesSection content={featuresSection} />
      <Headline content={thirdHeadline} />
      <TailoredItemsSection content={tailoredItems} />
      <AgenciesSection content={agenciesSection} />
    </Layout>
  );
};

export default VendorEdition;

export const fragment = graphql`
  fragment ImageAndTextDescription on ContentfulMarketingWebTranslationsConnection {
    nodes {
      id
      key
      title
      translation {
        translation
        childMdx {
          body
        }
      }
      image {
        url
      }
    }
  }
`;

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    seo: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.seo_settings" }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
        }
      }
    }

    heroSection: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.hero_section" }
        node_locale: { eq: $language }
      }
    ) {
      ...ImageAndTextDescription
      nodes {
        image {
          url
          title
          gatsbyImageData(width: 584, height: 520, formats: WEBP)
        }
      }
    }

    firstHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.first_headline" }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        title
        translation {
          childMdx {
            body
          }
        }
      }
    }

    solutionItems: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "vendor.ecommerce_executive"
            "vendor.marketing_manager"
            "vendor.sales_manager"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    brandTrustSection: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.trust_span" }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        title
      }
    }

    secondHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.second_headline" }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        translation {
          childMdx {
            body
          }
        }
      }
    }

    featuresSection: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "vendor.amazon_advertising"
            "vendor.content_seo"
            "vendor.customer_feedback"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
      nodes {
        image {
          title
          url
          gatsbyImageData(formats: WEBP)
        }
      }
    }

    thirdHeadline: allContentfulMarketingWebTranslations(
      filter: {
        key: { eq: "vendor.third_headline" }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        translation {
          childMdx {
            body
          }
        }
      }
    }

    tailoredItems: allContentfulMarketingWebTranslations(
      filter: {
        key: {
          in: [
            "vendor.global_range"
            "vendor.suited_big_teams"
            "vendor.ideal_large_sections"
          ]
        }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      ...ImageAndTextDescription
    }

    agenciesSection: allContentfulMarketingWebTranslations(
      filter: {
        key: { in: ["vendor.agency_headline", "vendor.agencies_cta"] }
        node_locale: { eq: $language }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        title
        translation {
          translation
        }
      }
    }
  }
`;

import { UIText, UITypography } from "@perpetua1/oasis-react";
import React from "react";
import Section from "../../../containers/Section";
import Markdown from "../../Markdown";

const shortcodes = {
  p: ({ children }) => <UIText>{children}</UIText>,
  ColorText: ({ children }) => (
    <UIText className="!mt-0 text-blue-2-dark">{children}</UIText>
  ),
  Author: ({ children }) => (
    <div className="mt-auto md:!mt-auto">{children}</div>
  )
};

const SolutionItems = ({ content }) => {
  const { nodes } = content;

  return (
    <Section className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap items-center md:justify-center gap-4 mb-11 lg:mb-22">
      {nodes.map(({ id, title, translation, image }) => (
        <div
          key={id}
          className=" bg-gray-6-light max-w-xs min-h-[450px] flex flex-col py-4"
        >
          <img width={100} src={image?.url} />
          <div className="grow md:basis-2/3 px-10 flex flex-col justify-center space-y-8 md:space-y-0">
            <h4 className="md:mt-2 md:mb-8 text-xl font-medium">{title}</h4>
            <Markdown shortcodes={shortcodes}>
              {translation?.childMdx.body}
            </Markdown>
          </div>
        </div>
      ))}
    </Section>
  );
};

export default SolutionItems;

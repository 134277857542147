import { UIColor, UIText, UITypography } from "@perpetua1/oasis-react";
import React from "react";
import Section from "../../../containers/Section";
import Brands, { BrandObject } from "../../Brands";

import CrocsLogo from "../../Brands/BrandSvgs/crocs.svg";
import KleenexLogo from "../../Brands/BrandSvgs/kleenex.svg";
import FranklinLogo from "../../Brands/BrandSvgs/franklin.svg";
import HuggiesLogo from "../../Brands/BrandSvgs/huggies.svg";

const BrandTrustSection = ({ content }) => {
  const { nodes } = content;
  const { title } = nodes[0];

  const brandsList: BrandObject[] = [
    { src: <CrocsLogo />, filter: true },
    { src: <KleenexLogo /> },
    { src: <FranklinLogo /> },
    { src: <HuggiesLogo /> }
  ];

  return (
    <Section className="mb-12">
      <UIText
        className="text-center"
        typography={UITypography.body3}
        color={UIColor.gray1}
      >
        {title}
      </UIText>
      <Brands brandsList={brandsList} />
    </Section>
  );
};

export default BrandTrustSection;

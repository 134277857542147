import { UIColor, UIText, UITypography } from "@perpetua1/oasis-react";
import React from "react";
import Section from "../../../containers/Section";
import Markdown from "../../Markdown";

const shortcodes = {
  p: ({ children }) => (
    <UIText
      className="text-center lg:px-36"
      typography={UITypography.body3}
      color={UIColor.gray1}
    >
      {children}
    </UIText>
  ),
  Heading: ({ children }) => (
    <h2 className="text-center font-semibold text-4xl md:text-5xl md:font-bold">
      {children}
    </h2>
  ),
  GradientText: ({ children }) => (
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-1 to-blue-1">
      {children}
    </span>
  )
};

const Headline = ({ content }) => {
  const { nodes } = content;
  const { translation } = nodes[0];

  return (
    <Section className="my-16 flex flex-col items-center space-y-6">
      <Markdown shortcodes={shortcodes}>{translation?.childMdx?.body}</Markdown>
    </Section>
  );
};

export default Headline;

import {
  UIButton,
  UIButtonVariant,
  UIColor,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import React from "react";
import { URL } from "../../../constants/destinations";
import Section from "../../../containers/Section";
import Link from "../../Link";

const AgencicesSection = ({ content }) => {
  const { nodes } = content;
  const { title, translation } = nodes[0];
  const { translation: ctaTranslation } = nodes[1];

  return (
    <Section className="!mx-4 lg:!mx-auto md:max-lg:!mx-12 rounded-md flex flex-col items-center bg-gradient-to-b from-indigo-1 to-indigo-1-dark py-16 md:py-32 md:px-32">
      <h2 className="text-white text-center text-4xl lg:text-5xl lg:font-bold mb-3">
        {title}
      </h2>
      <UIText
        className="mb-7 lg:px-72 text-center"
        typography={UITypography.body2}
        color={UIColor.indigoLight2}
      >
        {translation?.translation}
      </UIText>
      <UIButton
        variant={UIButtonVariant.secondary}
        className="!w-full md:!w-1/2 lg:!w-min text-indigo-1"
        text={<Link to={URL.Agency}>{ctaTranslation?.translation}</Link>}
      />
    </Section>
  );
};

export default AgencicesSection;

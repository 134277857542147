import { UIColor, UIText, UITypography } from "@perpetua1/oasis-react";
import React from "react";
import Section from "../../../containers/Section";

const TailoredItemsSection = ({ content }) => {
  const { nodes } = content;

  return (
    <Section className="flex flex-col md:flex-row md:flex-wrap md:justify-center gap-8 md:gap-0 mb-14 lg:mb-28">
      {nodes.map(({ id, title, translation, image }) => (
        <div
          className="md:basis-1/2 lg:basis-1/3 flex flex-col items-center space-y-4 px-8 lg:px-20 last:md:max-lg:mt-8"
          key={id}
        >
          <img className="" src={image?.url} width={56} height={56} />
          <div className="flex flex-col items-center space-y-2">
            <UIText typography={UITypography.header} color={UIColor.grayDark3}>
              {title}
            </UIText>
            <UIText
              className="text-center"
              typography={UITypography.body3}
              color={UIColor.gray1}
            >
              {translation?.translation}
            </UIText>
          </div>
        </div>
      ))}
    </Section>
  );
};

export default TailoredItemsSection;

import React from "react";
import classNames from "classnames";

import { UIText, UITypography } from "@perpetua1/oasis-react";

import Section from "../../../containers/Section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeaturesSection = ({ content }) => {
  const { nodes } = content;

  return (
    <Section className="flex flex-col gap-14 lg:gap-28">
      {nodes.map(({ id, title, translation, image }, index: number) => (
        <div
          className="flex flex-col lg:flex-row lg:even:flex-row-reverse gap-8 lg:gap-0"
          key={id}
        >
          <div
            className={classNames(
              "lg:basis-1/2 flex flex-col lg:justify-center lg:ml-6 space-y-4",
              {
                "lg:ml-8 lg:pr-24": index % 2 === 0
              },
              {
                "lg:pl-6": index % 2 !== 0
              }
            )}
          >
            <h4 className="text-center md:text-start text-4xl font-semibold">
              {title}
            </h4>
            <UIText
              className="text-center md:text-start"
              typography={UITypography.body3}
            >
              {translation?.translation}
            </UIText>
          </div>
          <GatsbyImage
            className="lg:basis-1/2"
            image={getImage(image)!}
            alt={image?.title}
          />
        </div>
      ))}
    </Section>
  );
};

export default FeaturesSection;

import {
  UIButton,
  UIButtonSize,
  UIButtonVariant,
  UIColor,
  UIText,
  UITypography
} from "@perpetua1/oasis-react";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Section from "../../../containers/Section";
import Markdown from "../../Markdown";
import { nav } from "../../../utils/navigation";
import { URL } from "../../../constants/destinations";

const shortcodes = {
  p: ({ children }) => (
    <UIText
      className="text-center md:text-start"
      typography={UITypography.body3}
      color={UIColor.gray1}
    >
      {children}
    </UIText>
  ),
  Heading: ({ children }) => (
    <h1 className="text-center md:text-start text-4xl font-semibold md:text-6xl md:font-bold">
      {children}
    </h1>
  ),
  GradientText: ({ children }) => (
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-1 to-blue-1">
      {children}
    </span>
  )
};

const HeroSection = ({ content }) => {
  const { nodes } = content;
  const { image, translation } = nodes[0];

  return (
    <Section className="mt-36 mb-16 lg:mb-52 flex flex-col lg:flex-row flex-wrap lg:flex-nowrap md:gap-8 lg:gap-4 items-center">
      <div className="flex flex-col items-center md:items-start basis-1/2 px-4 space-y-6">
        <Markdown shortcodes={shortcodes}>
          {translation?.childMdx.body}
        </Markdown>
        <UIButton
          className="hover:bg-indigo-1-dark"
          variant={UIButtonVariant.primary}
          size={UIButtonSize.large}
          text={"Get Started"}
          onClick={() => nav(URL.SignUp)}
        />
      </div>
      <GatsbyImage
        className="hidden md:inline"
        alt={image?.title}
        image={getImage(image)!}
      />
    </Section>
  );
};

export default HeroSection;
